import React, { useContext } from 'react'

import { BookingContext } from 'context/BookingContext'

import { Button } from 'components/Button/Button'

import useBookButtonLabel from '../hooks/useBookButtonLabel'
import useBookingErrors from '../hooks/useBookingErrors'
import styles from '../BookingQuote.module.scss'

export type BookingButtonProps = {
  announcement?: string
  isLoading?: boolean
  label?: string
  onClick?: VoidFunction
}

const BookingButton = ({
  announcement,
  label,
  isLoading,
  onClick,
}: BookingButtonProps) => {
  const { quote, loadingQuote } = useContext(BookingContext)
  const buttonLabel = useBookButtonLabel(label)
  const { errors } = useBookingErrors()

  return onClick ? (
    <div className={styles.booknow__section}>
      <Button
        className={styles.booknow__btn}
        disabled={loadingQuote || !!errors.quote}
        id="book_now_btn"
        isLoading={isLoading}
        onClick={onClick}
        text={buttonLabel}
      />
      {quote && <span>{announcement}</span>}
    </div>
  ) : null
}

export default React.memo(BookingButton)
