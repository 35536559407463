import classNames from 'classnames'

import styles from './PriceBreakdown.module.scss'

import { setPriceWithDecimals } from 'utils/Strings'

type ItemAmountProps = {
  amount: number
  isValueBold?: boolean
}

const ItemAmount = ({ amount, isValueBold = false }: ItemAmountProps) => {
  return (
    <span
      className={classNames(styles.price, {
        [styles.price__negative]: amount < 0,
        AB_TEST_GP385_DUE_BOLDS: isValueBold,
      })}
    >
      {setPriceWithDecimals(amount)}
    </span>
  )
}

export default ItemAmount
