import { useContext } from 'react'
import classNames from 'classnames'

import { selectIsMobile } from 'selectors/uiState'

import StarRatings from 'components/StarRatings/StarRatings'

import styles from './RatingAndReview.module.scss'

import { BookingQuoteContext } from '../context/BookingQuoteContext'

import { useSelect } from 'store'

type RatingAndReviewProps = {
  rating: number | undefined
  onClick?: VoidFunction
  className?: string
}

const starsStyleDefauts = {
  starDimension: '18px',
  starRatedColor: '#F9A11E',
  starSpacing: '1px',
}

const RatingAndReview = ({
  rating = 0,
  className,
  onClick,
}: RatingAndReviewProps) => {
  const { reviews } = useContext(BookingQuoteContext)
  const isMobile = useSelect(selectIsMobile)

  return reviews ? (
    <div className={classNames(styles.main, className)}>
      {rating && (
        <>
          <span>{rating}</span>
          <StarRatings
            rating={rating}
            uniqueId={'test'}
            {...starsStyleDefauts}
          />
        </>
      )}
      {isMobile ? (
        <>
          {reviews && (
            <span className={classNames(styles.reviews)}>
              {reviews} reviews
            </span>
          )}
        </>
      ) : (
        <button id="booking_reviews_btn" onClick={onClick}>
          {reviews} reviews
        </button>
      )}
    </div>
  ) : null
}

export default RatingAndReview
