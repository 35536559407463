import type { ListingAvailabilitySummaryResponse } from 'types/externalData'

export const fetchListingAvailabilitySummary = async (
  listingId: string,
): Promise<ListingAvailabilitySummaryResponse> => {
  try {
    const response = await fetch(`/api/calendar?listingId=${listingId}`)
    if (!response.ok) {
      throw new Error(`Server failed with a status ${response.status}`)
    }

    return response.json()
  } catch (e) {
    console.error(e)
    throw e
  }
}
