import { ReactNode } from 'react'

import BarLoader, { BarLoaderProps } from './BarLoader'

type WithBarLoaderProps = BarLoaderProps & {
  isLoading?: boolean
  children: ReactNode | null
}

const WithBarLoader = ({
  isLoading,
  children,
  ...props
}: WithBarLoaderProps) => {
  return isLoading ? <BarLoader {...props} /> : <>{children}</>
}

export default WithBarLoader
