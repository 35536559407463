import React from 'react'

interface Props {
  fill?: string
  height?: number
  width?: number
  className?: string
}

export const Spinner = ({
  fill = '#ffffff',
  height = 30,
  width = 30,
  className,
}: Props) => {
  return (
    <svg
      className={className}
      height={height}
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      width={width}
    >
      <g transform="rotate(0 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.9583333333333334s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(15 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.9166666666666666s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(30 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          ``
          <animate
            attributeName="opacity"
            begin="-0.875s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(45 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          ``
          <animate
            attributeName="opacity"
            begin="-0.8333333333333334s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(60 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.7916666666666666s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(75 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.75s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.7083333333333334s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(105 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.6666666666666666s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(120 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.625s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(135 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.5833333333333334s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(150 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.5416666666666666s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(165 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.5s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.4583333333333333s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(195 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.4166666666666667s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(210 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.375s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(225 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.3333333333333333s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(240 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.2916666666666667s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(255 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.25s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.20833333333333334s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(285 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.16666666666666666s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(300 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.125s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(315 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.08333333333333333s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(330 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="-0.041666666666666664s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(345 50 50)">
        <rect fill={fill} height="12" rx="3" ry="6" width="6" x="47" y="7">
          <animate
            attributeName="opacity"
            begin="0s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </rect>
      </g>
    </svg>
  )
}
