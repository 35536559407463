import React, { ReactNode } from 'react'

import { Spinner } from 'components/Spinner/Spinner'

import style from './Button.module.scss'

interface Props {
  id?: string
  text?: string
  isLoading?: boolean
  positionMode?: 'only-icon' | 'both' | 'no-icon'
  disabled?: boolean
  className?: string
  icon?: ReactNode
  spinnerIconColor?: string
  onClick?: () => void
  type?: 'submit' | 'reset' | 'button'
}

export const Button = ({
  className,
  id,
  text,
  isLoading,
  positionMode = 'only-icon',
  disabled,
  icon,
  spinnerIconColor = '#ffffff',
  type = 'button',
  onClick,
}: Props) => {
  return (
    <button
      className={`btn-primary ${className}`}
      disabled={disabled}
      id={id}
      onClick={onClick}
      type={type}
    >
      {!isLoading && icon}
      {positionMode === 'only-icon' && (
        <>
          {isLoading && (
            <Spinner className={style.spinner} fill={spinnerIconColor} />
          )}{' '}
          {!isLoading && text}
        </>
      )}

      {positionMode === 'both' && (
        <>
          {isLoading && (
            <Spinner className={style.spinner} fill={spinnerIconColor} />
          )}{' '}
          <span
            className={`${style.btntext} ${isLoading && style['btntext-icon']}`}
          >
            {text}
          </span>
        </>
      )}

      {positionMode === 'no-icon' && text}
    </button>
  )
}
