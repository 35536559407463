export const boomiAuth = {
  username: process.env.NEXT_PUBLIC_EVOLVE_API_USERNAME!,
  password: process.env.NEXT_PUBLIC_EVOLVE_API_PASSWORD!,
}

export const boomiAuthHeaders = process.browser
  ? new Headers({
      Authorization:
        'Basic ' + btoa(boomiAuth.username + ':' + boomiAuth.password),
    })
  : new Headers({
      Authorization:
        'Basic ' +
        Buffer.from(boomiAuth.username + ':' + boomiAuth.password).toString(
          'base64',
        ),
    })
