import { useState, useEffect, useCallback } from 'react'

import useAnalytics from './useAnalytics'

type UseDelayedRequestReport = {
  isValidating?: boolean
  requestType: any
  requestTimeout: number
  listingId: string
  autoReport?: boolean
  error?: TypeError | Response | Error | string
}

export const useDelayedRequestReport = ({
  isValidating,
  requestTimeout,
  listingId,
  requestType,
  autoReport = false,
  error,
}: UseDelayedRequestReport) => {
  const { reportDelayedRequest } = useAnalytics()
  const [initialRequestTime, setInitialRequestTime] = useState<any>(null)
  const sendReport = useCallback(
    (totalRequestTime: number, error?: any) => {
      const seconds = totalRequestTime / 1000
      const formattedTime = `${seconds.toFixed(1)} secs`
      const reportData = {
        responseTime: formattedTime,
        listingId,
        response: requestType,
      } as any
      if (error) reportData.error = error
      reportDelayedRequest(reportData)
    },
    [listingId, requestType, reportDelayedRequest],
  )

  const reportDelayedRequestOnHook = useCallback(
    (reportCallback?: () => void) => {
      if (isValidating) {
        setInitialRequestTime(Date.now())
      } else if (initialRequestTime || error) {
        const totalRequestTime = Date.now() - initialRequestTime
        if (totalRequestTime <= requestTimeout) return
        sendReport(totalRequestTime, error)
        reportCallback?.()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isValidating, reportDelayedRequest, listingId],
  )

  const reportDelayedRequestOnPromise = useCallback(
    (delayedPromise: Promise<any>, error?: any) => {
      const requestInitialMs = Date.now()

      const handleRequestFinished = () => {
        const totalRequestTime = Date.now() - requestInitialMs
        if (totalRequestTime <= requestTimeout) return

        sendReport(totalRequestTime, error)
      }

      // Purposefully not using `finally`. There's strange JS behavior when attaching .finally
      // that isn't chained to the original promise creation that results in an uncaught error even
      // when wrapped in a try/catch or .catch. A workaround is to use .then and .catch separately to
      // simulate a .finally
      delayedPromise.then(handleRequestFinished).catch(handleRequestFinished)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listingId, requestType, reportDelayedRequest],
  )

  useEffect(() => {
    if (autoReport) reportDelayedRequestOnHook()
  }, [autoReport, reportDelayedRequestOnHook])

  return {
    reportDelayedRequestOnHook,
    reportDelayedRequestOnPromise,
  }
}
