import classNames from 'classnames'

import type { BookingDates } from 'context/BookingContext'

import { useCancellationPolicy } from 'components/CancellationPolicy/useCancellationPolicy'

import styles from './CancellationAlert.module.scss'

import { DEFAULT_ALERT_MESSAGE } from 'constants/cancellationPolicy'

import MoneyIcon from 'assets/icons/icon-money.svg'

import type { Refunds } from 'types/externalData'

export type CancellationAlertProps = {
  className?: string
  dates: BookingDates
  hasBorderBottom?: boolean
  hasBorderTop?: boolean
  label?: string
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void
  refunds?: Refunds
  title?: string
}

const CancellationAlert = (props: CancellationAlertProps) => {
  const {
    className,
    dates,
    hasBorderBottom = false,
    hasBorderTop = false,
    label = 'See Details',
    onClick = () => {},
    title = 'Book with confidence',
    refunds,
  } = props

  const [alertRefund] = useCancellationPolicy(refunds, 'alert')
  const hasDates = !!(dates?.start && dates?.end)

  const { id, text } = alertRefund || {}
  const shouldRenderTitle = !hasDates || id === 'cash'
  const shouldRenderButton = hasDates

  return (
    <article
      className={classNames(
        styles.wrapper,
        {
          [styles.borderTop]: hasBorderTop,
          [styles.borderBottom]: hasBorderBottom,
        },
        className,
      )}
      id={id}
    >
      <section className={styles.section}>
        {shouldRenderTitle && <h5 className={styles.title}>{title}</h5>}
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: hasDates ? text : DEFAULT_ALERT_MESSAGE,
          }}
        />
        {shouldRenderButton && (
          <button className={styles.button} onClick={onClick}>
            {label}
          </button>
        )}
      </section>
      <MoneyIcon />
    </article>
  )
}

export { CancellationAlert }
