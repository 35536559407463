import { createContext, useState } from 'react'

export type BookingErrorsContextType = {
  datesError: string
  guestError: string
  setGuestError: (err: string) => void
  setDatesError: (err: string) => void
}

export const BookingErrorsContext = createContext<BookingErrorsContextType>(
  {} as BookingErrorsContextType,
)

type BookingErrorsProviderProps = {
  children?: React.ReactNode
}

export const BookingErrorsProvider: React.FC<BookingErrorsProviderProps> = (
  props,
) => {
  const [guestError, setGuestError] = useState('')
  const [datesError, setDatesError] = useState('')

  const state = { guestError, datesError, setGuestError, setDatesError }
  return (
    <BookingErrorsContext.Provider value={state}>
      {props.children}
    </BookingErrorsContext.Provider>
  )
}

export const withBookingErrorProvider = (Component) => {
  const BookingErrorProvider = (props) => (
    <BookingErrorsProvider>
      <Component {...props} />
    </BookingErrorsProvider>
  )
  BookingErrorProvider.displayName = 'withBookingErrorProvider'

  return BookingErrorProvider
}
