import React from 'react'
import classNames from 'classnames'

import styles from './TotalPrice.module.scss'

import { setPriceWithDecimals } from 'utils/Strings'

type TotalPriceProps = {
  className?: string
  price: number | undefined
}

const TotalPrice = ({ price, className }: TotalPriceProps) => {
  return price ? (
    <div className={classNames(styles.main, className)}>
      <span className={styles.price}>{setPriceWithDecimals(price)} Total</span>
      <span className={styles.included}>(includes taxes & fees)</span>
    </div>
  ) : null
}

export default React.memo(TotalPrice)
