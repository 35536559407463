import React, { useMemo } from 'react'

import { ListingAmenities } from 'components/Result-Details/ListingPage/ListingPage.types'

import { CombinedListing } from 'utils/staticData'

export const useListingAmenities = (
  listing: CombinedListing | undefined | null,
) =>
  useMemo<Array<ListingAmenities>>(() => {
    if (!listing || !listing['units']) return []

    return listing['units'][0]['amenities']
      .filter(({ category }) => category === 'Key Amenities')
      .filter(
        (obj, pos, arr) =>
          arr.findIndex((item) => item.name === obj.name) === pos,
      )
  }, [listing])
