import type { QuoteResponse } from 'types/externalData'

const calculateServiceFeePercent = (
  orderItems: QuoteResponse['orderItems'],
) => {
  const rateItems = orderItems.filter((item) => item.type === 'Rates')
  const rateTotal = rateItems.reduce((total, i) => total + i.total, 0)
  const feeItems = orderItems.filter((item) => item.type === 'Fees')
  const feeTotal = feeItems.reduce((total, i) => total + i.total, 0)
  const serviceFee = orderItems.filter(
    (item) => item.name === 'Guest Service Fee',
  )[0]?.total

  const serviceFeeValue = isNaN(serviceFee) ? 0 : serviceFee
  // Calculate what percentage of cost (rates and fees) service fee makes up
  return parseFloat(
    (
      (serviceFeeValue / (rateTotal + feeTotal - serviceFeeValue)) *
      100
    ).toFixed(2),
  )
}

export default calculateServiceFeePercent
