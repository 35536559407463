import styles from './BookingQuote.module.scss'
import useBookingErrors, { FieldErrorKeys } from './hooks/useBookingErrors'

const prioritizeDateError = (
  errors: Partial<Record<FieldErrorKeys, string>>,
) => {
  const { dates, ...rest } = errors
  return [dates, ...Object.values(rest)]
}

const BookingErrors = () => {
  const { errors } = useBookingErrors()
  const messages = prioritizeDateError(errors)
  return (
    <>
      {messages.map((errorDescription, i) => (
        <span className={styles.error__message} key={i}>
          {errorDescription}
        </span>
      ))}
    </>
  )
}

export default BookingErrors
