import { useContext, useMemo, useEffect, useState } from 'react'

import { BookingContext } from 'context/BookingContext'

import useListingAvailability from 'hooks/useListingAvailability'

import Dates, { DatesProps } from 'components/Search/Filters/Dates/Dates'

import useBookingErrors from '../hooks/useBookingErrors'
import styles from '../BookingQuote.module.scss'

import { LargeTabletBreakpoint } from 'config/Breakpoints'

import { errorCodeMap } from 'utils/Listings'

import type { MinStay } from 'types/externalData'

import { useSelect } from 'store'

const MISSING_DATES_ERROR = errorCodeMap['missingDates']

export type BookingDatesProps = {
  listingId: string
  minStay: MinStay
  quoteServiceError?: string
  onApplyDates: DatesProps['onApply']
  onClearDates: DatesProps['onClearDates']
  validateOnDirty?: boolean
}

const BookingDates: React.FC<BookingDatesProps> = ({
  listingId,
  minStay,
  onApplyDates,
  onClearDates,
  validateOnDirty,
}) => {
  const { dates } = useContext(BookingContext)
  const { availabilitySummary } = useListingAvailability({
    listingId,
    canTrackAvailabilityDelay: false,
  })
  const { errors, setDatesError } = useBookingErrors()
  const windowWidth = useSelect((state) => state.uiState.width)
  const hasDates = useMemo(
    () => (dates?.start && dates?.end ? true : false),
    [dates?.end, dates?.start],
  )
  const availability = availabilitySummary?.availabilityDates
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    const shouldValidate = validateOnDirty ? isDirty : true

    if (!shouldValidate) return

    const hasDates = dates?.start && dates?.end
    setDatesError(hasDates ? '' : MISSING_DATES_ERROR)
  }, [dates, isDirty, setDatesError, validateOnDirty])

  return availability ? (
    <div className={`${styles.bookingFilter} ${styles.bookingDates}`}>
      <Dates
        availability={availability}
        combinedInputs={hasDates}
        direction={windowWidth < LargeTabletBreakpoint ? 'left' : 'right'}
        hasError={!!errors.dates}
        initialEndDate={dates?.end}
        initialStartDate={dates?.start}
        listingId={listingId}
        minStay={minStay}
        onApply={(...args) => {
          onApplyDates!(...args)
          setIsDirty(true)
        }}
        onClearDates={() => {
          onClearDates!()
          setIsDirty(true)
        }}
        setDatesErrorMsg={setDatesError}
        small
      />
    </div>
  ) : null
}

export default BookingDates
