import React from 'react'
import classNames from 'classnames'

import styles from './AvgPricePerNight.module.scss'

import { setPrice as formatPrice } from 'utils/Strings'

export type AvgPricePerNightProps = {
  price: number | undefined
  className?: string
}

const AvgPricePerNight = ({ price = 0, className }: AvgPricePerNightProps) => {
  return price ? (
    <span className={classNames(styles.main, className)}>
      {formatPrice(price)} Avg. / night
    </span>
  ) : null
}

export default React.memo(AvgPricePerNight)
