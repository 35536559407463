import { useContext } from 'react'

import { BookingContext } from 'context/BookingContext'

import useBookingErrors from './useBookingErrors'

const useBookButtonLabel = (defaultLabel?: string): string => {
  const { guests, dates } = useContext(BookingContext)
  const { errors } = useBookingErrors()

  const { start, end } = dates ?? {}
  if (!start || !end || errors.dates) return 'Select Dates'
  const { adults } = guests
  if (!adults || errors.guests) return 'Select Guests'
  return defaultLabel || 'Book Now'
}

export default useBookButtonLabel
