import { useContext } from 'react'
import pickBy from 'lodash/pickBy'

import { BookingErrorsContext } from 'components/BookingQuote/context/BookingErrorsContext'

import { BookingQuoteContext } from '../context/BookingQuoteContext'

export type FieldErrorKeys = 'guests' | 'dates' | 'quote'
export type FieldErrors = Partial<Record<FieldErrorKeys, string>>

const sanitizeErrors = (errors: FieldErrors): FieldErrors => {
  return pickBy(errors, (value) => Boolean(value))
}

const priotitazeErrors = ({
  guests,
  dates,
  quote,
}: FieldErrors): FieldErrors => {
  return guests || dates ? { guests, dates } : { quote }
}

type UseBookingErrorsResponse = {
  errors: FieldErrors
  hasErrors: boolean
  setGuestError: (err: string) => void
  setDatesError: (err: string) => void
}

const useBookingErrors = (): UseBookingErrorsResponse => {
  const { quoteServiceError } = useContext(BookingQuoteContext)
  const { guestError, setGuestError, datesError, setDatesError } =
    useContext(BookingErrorsContext)

  const gatherErrors = () => {
    const allErrors: FieldErrors = {
      guests: guestError,
      dates: datesError,
      quote: quoteServiceError,
    }
    const errors = priotitazeErrors(allErrors)
    return sanitizeErrors(errors)
  }
  const errors = gatherErrors()

  return {
    errors,
    hasErrors: Boolean(Object.keys(errors).length),
    setGuestError,
    setDatesError,
  }
}

export default useBookingErrors
