import ReactTooltip from 'react-tooltip'

import styles from './PriceBreakdown.module.scss'

import InfoIcon from 'assets/icons/icon-info.svg'

type ItemToolTipProps = {
  info?: string
  label: string
}

const buildToolTip = (html: string) => {
  const div = document.createElement('div')
  div.innerHTML = html
  return div.textContent || div.innerText || ''
}

const ItemToolTip = ({ info, label }: ItemToolTipProps) => {
  return info ? (
    <>
      <InfoIcon
        className="pricebreakdown-tooltip"
        data-for={`data-tip-${label}`}
        data-tip={buildToolTip(info)}
      />
      <ReactTooltip
        className={styles.tooltip}
        effect="float"
        id={`data-tip-${label}`}
        place="bottom"
        type="light"
      />
    </>
  ) : null
}

export default ItemToolTip
