import classNames from 'classnames'

import type { ItemDetail } from './PriceBreakdown.types'
import styles from './PriceBreakdown.module.scss'

type PromotionItemProps = {
  onClick: VoidFunction
  item: ItemDetail
}

const PromotionItem = ({ item, onClick }: PromotionItemProps) => {
  return (
    <div className={classNames(styles.order__row, styles.discount)}>
      {item.label}

      <button className={styles.discount__remove_btn} onClick={onClick}>
        Remove
      </button>
    </div>
  )
}

export default PromotionItem
