export const scrollToId = (id: string) => {
  const el = document.getElementById(id)

  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

export const scrollToTop = (smooth?: boolean) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: smooth ? 'smooth' : 'auto',
  })
}
