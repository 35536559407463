import { boomiAuthHeaders } from 'config/BoomiAPI'

type ErrorResponse = Error & {
  info: any
  status: number
}

export const quoteFetcher = async (url: string, data: BodyInit) => {
  const headers = { 'Content-Type': 'application/json' }
  const res = await fetch('/api/quotes', {
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  })

  if (!res.ok) {
    const error = new Error(
      'An error occurred while fetching the data.',
    ) as ErrorResponse
    // Attach extra info to the error object.
    error.info = await res.json()
    error.status = res.status
    throw error
  }

  return res.json()
}

export const boomiAPIFetcher = async (url: string) => {
  const res = await fetch(url, {
    headers: boomiAuthHeaders,
  })

  if (!res.ok) {
    const error = new Error(
      'An error occurred while fetching the data.',
    ) as ErrorResponse
    // Attach extra info to the error object.
    error.info = await res.json()
    error.status = res.status
    throw error
  }

  return res.json()
}
