import { useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import useSWR from 'swr'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import { useDelayedRequestReport } from './useDelayedRequestReport'

import DatesConstants from 'constants/dates'

import type { Availability, AvailabilityUnit } from 'types/externalData'

import { fetchListingAvailabilitySummary } from 'service/ListingService'

const AVAILABILITY_REQUEST_TIMEOUT = 3000

type UseListingAvailabilityProps = {
  listingId?: string
  canTrackAvailabilityDelay?: boolean
}

export type AvailabilitySummary = {
  availabilityDates: Availability
  availabilityDatesMap: Record<string, AvailabilityUnit>
  minStayDates: Array<{ date: string; minStay: number }>
  averagePrice: string
  pricePerDay: Record<string, number>
}

type UseListingAvailabilityResponse = {
  availabilitySummary: AvailabilitySummary
}

const useListingAvailability = ({
  listingId,
  canTrackAvailabilityDelay = true,
}: UseListingAvailabilityProps): UseListingAvailabilityResponse => {
  const [currentListingId, setCurrentListingId] = useState<string>()
  const { isFallback: shouldRefreshAvailability } = useAugmentedRouter()
  const {
    data: listingAvailability,
    isValidating,
    error,
  } = useSWR(
    !shouldRefreshAvailability && listingId
      ? `fetchListingAvailabilitySummary/${listingId}`
      : null,
    () => fetchListingAvailabilitySummary(listingId!),
  )

  const { reportDelayedRequestOnHook } = useDelayedRequestReport({
    isValidating,
    requestType: 'Availability Summary Request',
    requestTimeout: AVAILABILITY_REQUEST_TIMEOUT,
    listingId: listingId || '',
    error,
  })

  const [isFirstRequestLoaded, setIsFirstRequestLoaded] = useState(false)
  useEffect(() => {
    if (!canTrackAvailabilityDelay) return

    if (isFirstRequestLoaded && currentListingId !== listingId)
      setIsFirstRequestLoaded(false)

    if (isFirstRequestLoaded) return

    setCurrentListingId(listingId)
    reportDelayedRequestOnHook(() => setIsFirstRequestLoaded(true))
  }, [
    isFirstRequestLoaded,
    isValidating,
    setIsFirstRequestLoaded,
    reportDelayedRequestOnHook,
    listingId,
    currentListingId,
    canTrackAvailabilityDelay,
  ])

  const availabilitySummary = useMemo((): AvailabilitySummary => {
    if (!listingAvailability) {
      return {
        availabilityDates: [],
        availabilityDatesMap: {},
        minStayDates: [],
        averagePrice: '',
        pricePerDay: {},
      }
    }

    const {
      apcdByDate,
      minStayByDate,
      averagePrice,
      priceByDay: prices,
    } = listingAvailability.availability

    const availableCodes = ['DC', 'DI', 'NI', 'DO', 'NO', 'DX']
    const blockedCodes = ['DX']
    const availabilityDates: Availability = []
    const availabilityDatesMap: Record<string, AvailabilityUnit> = {}

    const pricePerDay = prices[0]
    const availability = apcdByDate[0]
    for (let i = 0; i < Object.keys(availability).length; i++) {
      const date = Object.keys(availability)[i]
      const dayPrice = pricePerDay[date]
      const code = availability[date]
      const availabilityDate = {
        date: dayjs(date, DatesConstants.DEFAULT).format(),
        available: availableCodes.indexOf(code) > -1,
        blocked: blockedCodes.indexOf(code) > -1,
        price: dayPrice,
        code,
      }
      availabilityDates.push(availabilityDate)
      availabilityDatesMap[date] = availabilityDate
    }

    const minStayDates: AvailabilitySummary['minStayDates'] = []

    const minStay = minStayByDate[0]
    for (let i = 0; i < Object.keys(minStay).length; i++) {
      const date = Object.keys(minStay)[i]

      minStayDates.push({
        date: dayjs(date, 'YYYYMMDD').format(),
        minStay: minStay[date],
      })
    }

    return {
      availabilityDates,
      availabilityDatesMap,
      minStayDates,
      averagePrice,
      pricePerDay,
    }
  }, [listingAvailability])

  return {
    availabilitySummary,
  }
}

export default useListingAvailability
