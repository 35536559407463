import dayjs, { Dayjs } from 'dayjs'

import type { ItemDetail, OrderItem } from './PriceBreakdown.types'

import { setPrice as formatPrice } from 'utils/Strings'

import type { QuoteResponse } from 'types/externalData'

type UsePriceBreakdownProps = {
  quote: QuoteResponse
}

type UsePriceBreakdownResponse = {
  total: number
  breakdown: Record<string, OrderItem>
  promotions?: OrderItem
}

enum OrderItemType {
  RATES = 'Rates',
  FEES = 'Fees',
  INSURANCE = 'Insurance',
  TAXES = 'Taxes',
  CREDITS = 'Travel Credits',
}

enum RatePeriod {
  WEEK = 'Week',
  WEEKS = 'Weeks',
  NIGHT = 'Night',
  NIGHTS = 'Nights',
  MONTH = 'Month',
  MONTHS = 'Months',
}

const RATE_DATE_FORMAT = 'ddd. MMM D, YYYY'
const LONGER_BOOKING_TRESHOLD = 10

const addNewOrderItem = (
  breakdown: UsePriceBreakdownResponse['breakdown'],
  type: string,
): void => {
  breakdown[type] = {
    label: type,
    total: 0,
    items: [],
  }
}

const getLastRateDate = (
  orderItems: Array<ItemDetail>,
  checkInDate: Dayjs,
): Dayjs => {
  const rates = [...orderItems]
  const lastRate = rates.pop()
  return lastRate
    ? dayjs(lastRate.label, RATE_DATE_FORMAT)
    : checkInDate.subtract(1, 'day')
}

// rateInfo is always going to come in the format [number] [period] @ [price]
// e.g 4 Nights @ 72.00
const parseRateItemName = (
  rateInfo: string,
  startDate: Dayjs,
  totalPrice: number,
): Array<ItemDetail> => {
  const [numberOfPeriods, period] = rateInfo.split(' ')
  let totalOfNights = +numberOfPeriods
  if ([RatePeriod.WEEK, RatePeriod.WEEKS].includes(period as RatePeriod)) {
    totalOfNights = +numberOfPeriods * 7
  }
  if ([RatePeriod.MONTH, RatePeriod.MONTHS].includes(period as RatePeriod)) {
    totalOfNights = +numberOfPeriods * 30
  }
  const valuePerNight = totalPrice / totalOfNights
  const orderItems: Array<ItemDetail> = []
  let initDate = startDate
  const endDate = startDate.add(totalOfNights - 1, 'day')
  while (!initDate.isAfter(endDate)) {
    initDate = initDate.add(1, 'day')
    orderItems.push({
      label: initDate.format(RATE_DATE_FORMAT),
      value: valuePerNight,
    })
  }

  return orderItems
}

const addRateItem = (
  breakdown: UsePriceBreakdownResponse['breakdown'],
  checkIn: Dayjs,
  orderItem: QuoteResponse['orderItems'][0],
): void => {
  const { type, total, name } = orderItem
  const lastRateDate = getLastRateDate(breakdown[type].items, checkIn)
  const rates = parseRateItemName(name, lastRateDate, total)
  breakdown[type].items.push(...rates)
  breakdown[type].total += total
}

const addTaxItem = (
  breakdown: UsePriceBreakdownResponse['breakdown'],
  orderItem: QuoteResponse['orderItems'][0],
): void => {
  const { type, total, name, helpText } = orderItem
  breakdown[type].items.push({
    label: name,
    value: total,
    helpText: helpText || '',
  })
  breakdown[type].total += total
}

const convertToLongerRates = (rates: OrderItem): void => {
  const totalNights = rates.items.length
  const avgPrice = rates.total / totalNights
  rates.label = `${formatPrice(avgPrice)} X ${totalNights} nights`
  rates.items = []
}

const getPromotions = (
  promotions: QuoteResponse['promotions'],
): OrderItem | undefined => {
  if (!promotions) return undefined

  const validPromotions =
    promotions.filter(
      (promotion) => !promotion.promoCodeError && promotion.amount,
    ) || []
  if (!validPromotions.length) {
    return undefined
  }

  return {
    label: OrderItemType.CREDITS,
    items: validPromotions.map(({ amount, promoCode }) => ({
      label: promoCode,
      value: amount,
      helpText: '',
    })),
    total: validPromotions.reduce((acc, { amount }) => acc + amount, 0),
  }
}

const buildPriceBreakdown = (
  orderItems: QuoteResponse['orderItems'],
  checkInDate: string,
) => {
  const checkIn = dayjs(checkInDate)
  return orderItems.reduce((acc, orderItem) => {
    const { total, helpText, name } = orderItem
    const orderType = orderItem.type

    const orderKey = [OrderItemType.RATES, OrderItemType.TAXES].includes(
      orderType as OrderItemType,
    )
      ? orderType
      : name
    const isOrderItemInBreakdown = Object.keys(acc).includes(orderKey)

    if (!isOrderItemInBreakdown) {
      addNewOrderItem(acc, orderKey)
    }

    if (orderType === OrderItemType.RATES) {
      addRateItem(acc, checkIn, orderItem)
    } else if (orderType === OrderItemType.TAXES) {
      addTaxItem(acc, orderItem)
    } else {
      acc[orderKey].helpText = helpText || ''
      acc[orderKey].total = total
    }

    return acc
  }, {} as UsePriceBreakdownResponse['breakdown'])
}

const usePriceBreakdown = ({
  quote,
}: UsePriceBreakdownProps): UsePriceBreakdownResponse => {
  const {
    orderItems,
    price: { total },
    checkInDate,
    promotions: promos,
  } = quote
  const breakdown = buildPriceBreakdown(orderItems, checkInDate)
  const promotions = getPromotions(promos)

  const rates = breakdown[OrderItemType.RATES]
  if (rates.items.length > LONGER_BOOKING_TRESHOLD) {
    convertToLongerRates(rates)
  }

  return { breakdown, total, promotions }
}

export default usePriceBreakdown
