import {
  Elements,
  PaymentMethodMessagingElement,
} from '@stripe/react-stripe-js'
import { StripeElementsOptions } from '@stripe/stripe-js'

import getStripe from 'utils/getStripejs'

const stripeElementOptions: StripeElementsOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800',
    },
  ],
  appearance: {
    variables: {
      fontFamily: 'Montserrat',
      fontSizeBase: '13px',
    },
  },
}

interface AffirmMessageProps {
  total?: number
  isMobile?: boolean
}

const getFontSizeBase = () => {
  if (typeof window !== 'undefined') {
    if (window.innerWidth > 400) {
      return '16px'
    } else if (window.innerWidth >= 375) {
      return '14px'
    }
  }
  return '12px'
}

export const AffirmMessage = ({
  total,
  isMobile = false,
}: AffirmMessageProps) => {
  if (!total || total < 500) return null

  const integerAmount = Math.round(total * 100)

  let options: StripeElementsOptions = stripeElementOptions

  if (isMobile) {
    options = {
      ...options,
      appearance: {
        ...options.appearance,
        variables: {
          ...options.appearance?.variables,
          fontSizeBase: getFontSizeBase(),
        },
      },
    }
  }

  return (
    <Elements options={options} stripe={getStripe()}>
      <PaymentMethodMessagingElement
        options={{
          amount: integerAmount,
          currency: 'USD',
          countryCode: 'US',
          paymentMethodTypes: ['affirm'],
        }}
      />
    </Elements>
  )
}
