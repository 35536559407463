import styles from './TextPolicy.module.scss'

import { DEFAULT_POLICY_NOTE } from 'constants/cancellationPolicy'

import type { MappedRefunds } from 'types/externalData'

export type TextPolicyProps = {
  refunds: MappedRefunds
}

const TextPolicy = (props: TextPolicyProps) => {
  const { refunds } = props
  const [lastRefund] = refunds
  const hasRefunds = refunds.length > 1 && lastRefund.id !== 'none'
  return (
    <article className={styles.article} data-testid="TextPolicy">
      {refunds.map((refund) => (
        <p dangerouslySetInnerHTML={{ __html: refund.text }} key={refund.id} />
      ))}
      {hasRefunds ? (
        <p>
          <span>Note:</span> {DEFAULT_POLICY_NOTE}
        </p>
      ) : null}
    </article>
  )
}

export { TextPolicy }
