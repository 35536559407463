import React, { ReactElement, useContext, useMemo, useState } from 'react'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import dayjs from 'dayjs'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { BookingContext } from 'context/BookingContext'

import { useCertainPage } from 'hooks/useCertainPage'

import WithBarLoader from 'components/Loader/WithBarLoader'
import { AffirmMessage } from 'components/Affirm/AffirmMessage'
import { CancellationAlert } from 'components/CancellationAlert/CancellationAlert'
import Modal from 'components/Modal/Modal'
import { CancellationPolicy } from 'components/CancellationPolicy/CancellationPolicy'

import styles from './BookingQuote.module.scss'
import TotalPrice from './TotalPrice'
import AvgPricePerNight from './AvgPricePerNight'
import RatingAndReview from './RatingAndReview'
import PriceBreakdown from './PriceBreakdown'
import ScheduledPayments from './ScheduledPayments'
import BookingErrors from './BookingErrors'
import useBookingErrors from './hooks/useBookingErrors'
import BookingDates from './BookingDates'
import BookingGuest from './BookingGuest'
import BookingButton from './BookingButton'
import { BookingQuoteContext } from './context/BookingQuoteContext'

import { scrollToId } from 'utils/scroll'

import CloseIcon from 'assets/icons/icon-close.svg'

import type { Refunds } from 'types/externalData'

type BookingQuoteProps = {
  className?: string
  isLoadingQuote: boolean
  isModalModeOn?: boolean
  numberOfReviews?: number
  quoteServiceError?: string
  children: ReactElement[]
  toggleModalMode?: (isOpen: boolean) => void
  isMobile?: boolean
  cancellationRefunds?: Refunds
}

const getChildByType = <T,>(
  children: (React.ReactNode | React.ReactFragment | React.ReactPortal)[],
  type: T,
) => {
  return children.find((child: any) => child?.type === type)
}

const BookingQuote = ({
  className,
  isLoadingQuote,
  isModalModeOn,
  quoteServiceError = '',
  numberOfReviews = 0,
  toggleModalMode,
  isMobile,
  cancellationRefunds,
  ...props
}: BookingQuoteProps) => {
  const children = React.Children.toArray(props.children)
  const { quote, bookingRef, dates, guests } = useContext(BookingContext)
  const [cancellationModalIsOpen, setCancellationModalIsOpen] = useState(false)
  const { hasErrors } = useBookingErrors()
  const bookingQuoteContextState = useMemo(
    () => ({ isLoadingQuote, quoteServiceError, reviews: numberOfReviews }),
    [isLoadingQuote, quoteServiceError, numberOfReviews],
  )
  const paymentSchedule = quote?.price?.paymentSchedule

  const showAveragePricePerNightFlag = useFeatureIsOn('average-price-per-night')

  const isTotalPayment =
    Array.isArray(paymentSchedule) && paymentSchedule.length === 1

  const cancellationIsAnchorMode = useCertainPage(
    /vacation-rentals.*\/[0-9]/,
    /vacation-rentals\/[0-9]+(?!.*\binquiry_modal=open\b)(\?.*)?$/,
  )
  const cancellationIsModalMode = useCertainPage(
    /booking.*\/summary/,
    /booking.*\/payment-details/,
    /vacation-rentals\/[0-9].*\?[^]*inquiry_modal=open/,
  )

  const hasAdults = guests?.adults > 0

  const now = dayjs()
  const startDate = dayjs(dates?.start)
  const endDate = dayjs(dates?.end)

  const hasValidDates = startDate.isAfter(now) && endDate.isAfter(now)
  const hasCancellationPolicyData =
    !isLoadingQuote && !quoteServiceError && !!cancellationRefunds
  const shouldRenderDesktopCancellationAlert =
    !isMobile && (hasCancellationPolicyData || !hasValidDates)

  const hasQuote = !!quote

  const cancellationModalPortal =
    typeof document !== 'undefined'
      ? createPortal(
          <Modal
            isOpen={cancellationModalIsOpen}
            onCloseClick={() => setCancellationModalIsOpen(false)}
          >
            <CancellationPolicy dates={dates} refunds={cancellationRefunds} />
          </Modal>,
          document.body,
        )
      : null

  const handleCancellationAlertClick = () => {
    if (cancellationIsAnchorMode) {
      scrollToId('cancellationPolicy')
    }

    if (cancellationIsModalMode) {
      setCancellationModalIsOpen(true)
    }
  }

  const shouldRenderAveragePrice =
    showAveragePricePerNightFlag ||
    (hasValidDates && !isLoadingQuote && hasQuote)

  return (
    <BookingQuoteContext.Provider value={bookingQuoteContextState}>
      <div
        className={classNames(className, styles.booking, {
          [styles.booking__quote_complete]: quote && !isLoadingQuote,
          [styles.booking__reviews_none]: !numberOfReviews && !isLoadingQuote,
          [styles.booking__quote_incomplete]: !quote && !isLoadingQuote,
          [styles.booking__quote_loading]: isLoadingQuote,
          [styles.modal__mode_on]: isModalModeOn,
        })}
        ref={bookingRef}
      >
        <div className={classNames('booking__main', styles.main)}>
          {isModalModeOn && toggleModalMode && (
            <button
              aria-label="close"
              className={styles.modal__btn_close}
              onClick={() => {
                toggleModalMode(false)
              }}
            >
              <CloseIcon />
            </button>
          )}
          {!hasValidDates && !showAveragePricePerNightFlag && (
            <span className={styles.booking__quote_no_dates}>
              Add dates for prices
            </span>
          )}
          {hasValidDates && !hasAdults && !showAveragePricePerNightFlag && (
            <span className={styles.booking__quote_no_dates}>
              Add guests for prices
            </span>
          )}
          <div className={styles.section__top}>
            <WithBarLoader
              height={46}
              isLoading={isLoadingQuote}
              useTransparent
              width={'76%'}
            >
              {getChildByType(children, TotalPrice)}
            </WithBarLoader>
            <div className={styles.avg__price}>
              {shouldRenderAveragePrice &&
                getChildByType(children, AvgPricePerNight)}
            </div>
            {getChildByType(children, RatingAndReview)}
            <AffirmMessage total={quote?.price?.total} />
          </div>
          {getChildByType(children, BookingDates)}
          {getChildByType(children, BookingGuest)}
          <BookingErrors />
          {!hasErrors && !isLoadingQuote && (
            <WithBarLoader
              height={24}
              isLoading={isLoadingQuote}
              marginBottom={8}
              numberOfBars={3}
              width="100%"
            >
              {quote && getChildByType(children, PriceBreakdown)}
            </WithBarLoader>
          )}
          <div className={styles.booking__bottom_section}>
            {getChildByType(children, BookingButton)}
            {isTotalPayment ? null : (
              <WithBarLoader
                height={24}
                isLoading={isLoadingQuote}
                marginBottom={8}
                numberOfBars={2}
                width="100%"
              >
                <ScheduledPayments payments={quote?.price} />
              </WithBarLoader>
            )}
          </div>
          {shouldRenderDesktopCancellationAlert && (
            <WithBarLoader
              height={24}
              numberOfBars={3}
              useTransparent={false}
              width="100%"
            >
              <CancellationAlert
                dates={dates}
                hasBorderTop
                onClick={handleCancellationAlertClick}
                refunds={cancellationRefunds}
              />
              {cancellationModalPortal}
            </WithBarLoader>
          )}
        </div>
      </div>
    </BookingQuoteContext.Provider>
  )
}

BookingQuote.Total = TotalPrice
BookingQuote.AvgPrice = AvgPricePerNight
BookingQuote.RatingAndReview = RatingAndReview
BookingQuote.Dates = BookingDates
BookingQuote.Guests = BookingGuest
BookingQuote.PriceBreakdown = PriceBreakdown
BookingQuote.Button = BookingButton

export default BookingQuote
