import classNames from 'classnames'

import styles from './TimelinePolicy.module.scss'

import type { MappedRefunds } from 'types/externalData'

export type TimelinePolicyProps = {
  refunds: MappedRefunds
}

const TimelinePolicy = (props: TimelinePolicyProps) => {
  const { refunds } = props

  return (
    <ul className={styles.list} data-testid="TimelinePolicy">
      {refunds.map((refund, index) => {
        const isFirst = index === 0
        const isLast = index === refunds.length - 1
        const isCash = refund.id === 'cash'
        const isCredit = refund.id === 'credit'
        const isNone = refund.id === 'none'
        const messageDataTestId = `message-${index}`
        const dateDataTestId = `date-${index}`
        return (
          <li
            className={classNames(styles.item, {
              [styles.isFirst]: isFirst,
              [styles.isLast]: isLast,
            })}
            key={refund.id}
            tabIndex={0}
          >
            <div className={styles.itemRow}>
              <div
                className={classNames(styles.itemColumnA, {
                  [styles.itemColumnACash]: isCash,
                  [styles.itemColumnACredit]: isCredit,
                  [styles.itemColumnANone]: isNone,
                })}
              >
                <p
                  className={styles.itemMessage}
                  dangerouslySetInnerHTML={{ __html: refund.text }}
                  data-testid={messageDataTestId}
                />
              </div>
              <div className={styles.itemColumnB}>
                {isFirst ? (
                  <div className={styles.dateLayout}>
                    <p className={styles.itemDate}>Today</p>
                    <div className={styles.itemCircle} />
                  </div>
                ) : null}
                <div
                  className={classNames(styles.itemLine, {
                    [styles.itemLineCash]: isCash,
                    [styles.itemLineCredit]: isCredit,
                    [styles.itemLineNone]: isNone,
                  })}
                />
                <div className={styles.dateLayout}>
                  <p className={styles.itemDate} data-testid={dateDataTestId}>
                    {isLast ? `Check-in on ${refund.date}` : refund.date}
                  </p>
                  <div className={styles.itemCircle} />
                </div>
              </div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export { TimelinePolicy }
