import { useContext, useState, useEffect } from 'react'

import { BookingContext } from 'context/BookingContext'

import Guests, { GuestsProps } from 'components/Search/Filters/Guests/Guests'
import { ListingAmenities } from 'components/Result-Details/ListingPage/ListingPage.types'

import useBookingErrors from '../hooks/useBookingErrors'
import styles from '../BookingQuote.module.scss'

import { errorCodeMap } from 'utils/Listings'
import type { CombinedListing } from 'utils/staticData'

const MISSING_GUESTS_ERROR = errorCodeMap['missingGuests']

export type BookingGuestProps = {
  listing?: CombinedListing | undefined
  onApplyGuests: GuestsProps['onApply']
  onClearGuests: GuestsProps['onClearGuests']
  validateOnDirty?: boolean
  amenities: Array<ListingAmenities>
}

const BookingGuest = ({
  listing,
  onApplyGuests,
  onClearGuests,
  validateOnDirty,
  amenities,
}: BookingGuestProps) => {
  const { errors, setGuestError } = useBookingErrors()
  const { guests } = useContext(BookingContext)
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    const shouldValidate = validateOnDirty ? isDirty : true

    if (!shouldValidate) return

    const hasGuests = guests.adults > 0
    setGuestError(hasGuests ? '' : MISSING_GUESTS_ERROR)
  }, [guests.adults, isDirty, setGuestError, validateOnDirty])

  const petsAllowed = !!amenities.filter(({ name }) => name === 'Pets Allowed')
    .length

  return listing ? (
    <div className={styles.bookingFilter}>
      <Guests
        hasError={!!errors.guests}
        hasIcon={true}
        max={
          listing['Max Occupancy'] ??
          listing['units'][0]['configuration']['sleeps'] ??
          undefined
        }
        onApply={(...arg) => {
          onApplyGuests(...arg)
          setIsDirty(true)
        }}
        onClearGuests={() => {
          onClearGuests()
          setIsDirty(true)
        }}
        petsAllowed={petsAllowed}
        small={true}
        value={guests}
      />
    </div>
  ) : null
}

export default BookingGuest
