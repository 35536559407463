import React, { useState, useContext, useCallback } from 'react'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import classNames from 'classnames'

import { BookingContext, BookingContextType } from 'context/BookingContext'

import styles from './PriceBreakdown.module.scss'
import usePriceBreakdown from './usePriceBreakdown'
import OrderItemSummary from './OrderItemSummary'
import OrderItemRow from './OrderItemRow'
import PromotionItem from './PromotionItem'
import type { PriceBreakdownProps } from './PriceBreakdown.types'

import { setPriceWithDecimals } from 'utils/Strings'

dayjs.extend(LocalizedFormat)

const PriceBreakdown: React.FC<PriceBreakdownProps> = ({
  quote,
  className,
  expandedAll,
  onToggleExpandPriceItem,
}) => {
  const { setShouldApplyPromo, setPromosApplied }: BookingContextType =
    useContext(BookingContext)
  const [isExpanded, setIsExpanded] = useState<Record<string, boolean>>({})
  const { breakdown, total, promotions } = usePriceBreakdown({
    quote: quote!,
  })

  const removePromotion = (promotion: string) => {
    setShouldApplyPromo(true)
    setPromosApplied((promos: string[]) =>
      promos.filter((promo: string) => promo !== promotion),
    )
  }

  const toggleIsItemExpanded = useCallback(
    (itemKey: string) => {
      const isItemExpanded = isExpanded[itemKey]
      setIsExpanded({ ...isExpanded, [itemKey]: !isItemExpanded })
      onToggleExpandPriceItem &&
        onToggleExpandPriceItem(itemKey, !isItemExpanded)
    },
    [isExpanded, setIsExpanded, onToggleExpandPriceItem],
  )

  const orderKeys = Object.keys(breakdown)

  return (
    <div className={classNames(styles.main, className)}>
      {breakdown ? (
        <ul className={styles.price__breakdown}>
          {orderKeys.map((key) => {
            const orderItem = breakdown[key]
            const isItemExpanded = isExpanded[key] || expandedAll
            return (
              <li
                className={classNames(styles.order__item, {
                  [styles.order__item_expanded]: isItemExpanded,
                })}
                key={key}
              >
                <OrderItemSummary
                  isExpandableDisabled={expandedAll}
                  item={orderItem}
                  onClick={() => {
                    toggleIsItemExpanded(key)
                  }}
                />
                {isItemExpanded && orderItem.items.length > 0 && (
                  <div className={styles.order__detail_panel}>
                    {orderItem.items.map((item) => {
                      return <OrderItemRow item={item} key={item.label} />
                    })}
                  </div>
                )}
              </li>
            )
          })}
          {promotions && (
            <li
              className={classNames(styles.order__item, {
                [styles.order__item_expanded]:
                  isExpanded[promotions.label] || expandedAll,
              })}
            >
              <OrderItemSummary
                item={promotions}
                onClick={() => {
                  toggleIsItemExpanded(promotions.label)
                }}
              />

              {(isExpanded[promotions.label] || expandedAll) && (
                <div className={styles.order__detail_panel}>
                  {promotions.items.map((item) => {
                    return (
                      <PromotionItem
                        item={item}
                        key={item.label}
                        onClick={() => {
                          removePromotion(item.label)
                        }}
                      />
                    )
                  })}
                </div>
              )}
            </li>
          )}
          <li className={classNames(styles.order__item, styles.total)}>
            <div className={styles.order__row}>
              <span>Total</span>
              <span>{total && setPriceWithDecimals(total)}</span>
            </div>
          </li>
        </ul>
      ) : null}
    </div>
  )
}

export default PriceBreakdown
