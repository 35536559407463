import type { Refunds } from 'types/externalData'

const DEFAULT_TEXT_MESSAGE =
  'Free cancellation on most trips if cancelled within 48 hours of booking. For more detailed cancellation policies, please enter your trip dates.'

const TEST_PAYLOADS: {
  [key: string]: Refunds
} = {
  a: [
    {
      refundPercent: 100,
      refundType: 'cash',
      gracePeriod: false,
      gracePeriodHours: 0,
      refundTime: '03:30:00',
      refundDate: '2023-02-10',
    },
    {
      refundPercent: 100,
      refundType: 'credit',
      gracePeriod: false,
      gracePeriodHours: 0,
      refundTime: '00:00:00',
      refundDate: '2023-02-10',
    },
    {
      refundPercent: 0,
      refundType: 'none',
      gracePeriod: false,
      gracePeriodHours: 0,
      refundTime: '00:00:00',
      refundDate: '2023-02-10',
    },
  ],
  b: [
    {
      refundPercent: 100,
      refundType: 'cash',
      gracePeriod: true,
      gracePeriodHours: 48,
      refundTime: '15:00:00',
      refundDate: '2023-02-10',
    },
    {
      refundPercent: 100,
      refundType: 'credit',
      gracePeriod: false,
      gracePeriodHours: 0,
      refundTime: '00:00:00',
      refundDate: '2023-02-10',
    },
    {
      refundPercent: 0,
      refundType: 'none',
      gracePeriod: false,
      gracePeriodHours: 0,
      refundTime: '00:00:00',
      refundDate: '2023-02-10',
    },
  ],
  c: [
    {
      refundPercent: 100,
      refundType: 'cash',
      gracePeriod: true,
      gracePeriodHours: 48,
      refundTime: '15:00:00',
      refundDate: '2023-02-10',
    },
    {
      refundPercent: 0,
      refundType: 'none',
      gracePeriod: false,
      gracePeriodHours: 0,
      refundTime: '00:00:00',
      refundDate: '2023-02-10',
    },
  ],
  d: [
    {
      refundPercent: 100,
      refundType: 'credit',
      gracePeriod: false,
      gracePeriodHours: 0,
      refundTime: '00:00:00',
      refundDate: '2023-02-10',
    },
    {
      refundPercent: 0,
      refundType: 'none',
      gracePeriod: false,
      gracePeriodHours: 0,
      refundTime: '00:00:00',
      refundDate: '2023-02-10',
    },
  ],
  e: [
    {
      refundPercent: 100,
      refundType: 'credit',
      gracePeriod: true,
      gracePeriodHours: 48,
      refundTime: '00:00:00',
      refundDate: '2023-02-10',
    },
    {
      refundPercent: 0,
      refundType: 'none',
      gracePeriod: false,
      gracePeriodHours: 0,
      refundTime: '00:00:00',
      refundDate: '2023-02-10',
    },
  ],
  f: [
    {
      refundPercent: 0,
      refundType: 'none',
      gracePeriod: false,
      gracePeriodHours: 0,
      refundTime: '00:00:00',
      refundDate: '2023-02-10',
    },
  ],
}

const DEFAULT_POLICY_NOTE =
  'Cancellation policy times are in the listing’s time zone.'

const DEFAULT_ALERT_MESSAGE =
  'Most trips qualify for free 48 hour cancellation. Add dates for more information.'

export {
  DEFAULT_TEXT_MESSAGE,
  TEST_PAYLOADS,
  DEFAULT_POLICY_NOTE,
  DEFAULT_ALERT_MESSAGE,
}
