import React, { ReactElement } from 'react'
import dayjs from 'dayjs'
import classNames from 'classnames'

import styles from './ScheduledPayments.module.scss'

import OrderItemRow from '../PriceBreakdown/OrderItemRow'

import { setPriceWithDecimals } from 'utils/Strings'

import type { QuoteResponse } from 'types/externalData'

type ScheduledPaymentsProps = {
  payments?: QuoteResponse['price']
  children?: (styles: { [key: string]: string }, total: number) => ReactElement
}

const DATE_FORMAT = 'MMM. DD, YYYY'
const MAX_AMOUNT_PAYMENTS = 2

const ScheduledPayments = ({ payments, children }: ScheduledPaymentsProps) => {
  if (!payments || !payments.paymentSchedule?.length) {
    return null
  }

  const remainingCharge = setPriceWithDecimals(payments.amountDueLater)
  const nextPaymentDate = dayjs(
    [...payments.paymentSchedule].pop()!.dueDate,
  ).format(DATE_FORMAT)
  const isScheduledPayment =
    payments.paymentSchedule.length === MAX_AMOUNT_PAYMENTS

  return (
    <div className={classNames('scheduled__payments', styles.main)}>
      {isScheduledPayment && (
        <>
          <div className={styles.row}>
            <OrderItemRow
              isLabelBold={false}
              item={{ label: 'Due Today', value: payments.amountDueNow }}
            />
          </div>
          <div className={styles.row}>
            <OrderItemRow
              isLabelBold={false}
              item={{
                label: `Due ${nextPaymentDate}`,
                value: payments.amountDueLater,
                helpText: `The balance of ${remainingCharge} will be automatically charged on this date`,
              }}
            />
          </div>
        </>
      )}
      {children?.(styles, payments.total)}
    </div>
  )
}

export default React.memo(ScheduledPayments)
