import styles from './PriceBreakdown.module.scss'
import type { OrderItem } from './PriceBreakdown.types'
import ItemToolTip from './ItemTooltip'
import OrderItemRow from './OrderItemRow'
import ItemAmount from './ItemAmout'

import CaretDownIcon from 'assets/icons/icon-caretDown.svg'

type OrderItemSummaryProps = {
  onClick?: VoidFunction
  item: OrderItem
  isExpandableDisabled?: boolean
}

const OrderItemSummary = ({
  onClick,
  item,
  isExpandableDisabled = false,
}: OrderItemSummaryProps) => {
  const { items, label, total, helpText } = item
  const isExpandable = !isExpandableDisabled && !!items?.length
  return isExpandable ? (
    <div className={styles.order__row}>
      <button className={styles.order__row_btn} onClick={onClick}>
        {label}
        <CaretDownIcon className={styles.caret} />
        <ItemToolTip info={helpText} label={label} />
      </button>
      <ItemAmount amount={total} />
    </div>
  ) : (
    <OrderItemRow item={{ value: total, label, helpText }} />
  )
}

export default OrderItemSummary
