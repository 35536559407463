import { createContext } from 'react'

export type BookingQuoteContextType = {
  isLoadingQuote: boolean
  quoteServiceError: string
  reviews: number
}

export const BookingQuoteContext = createContext<BookingQuoteContextType>({
  isLoadingQuote: false,
  quoteServiceError: '',
  reviews: 0,
})
