import React from 'react'

import useTimeout from 'hooks/useTimeout'

import style from './Checkbox.module.scss'

export type CheckboxProps = {
  label?: string
  hasError?: boolean
  onChange: (value: any) => void
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  id,
  name,
  value,
  disabled,
  checked,
  defaultChecked,
  onChange,
  hasError,
}) => {
  const timeout = useTimeout()

  return (
    <div
      className={`${style.checkbox} ${
        timeout.isRunning ? style.touchHilight : ''
      }`}
      onClick={() => {
        if (document.body.classList.value.includes('Search')) {
          timeout.setIsRunning(true)
        }
      }}
    >
      <input
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={() => {
          onChange(value)
        }}
        type="checkbox"
        value={value}
      />
      <label className={`${hasError ? style.hasError : ''}`} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}
export default Checkbox
