import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

import DatesConstants from 'constants/dates'
/* This plugin enables formatting ordinal dates. */
dayjs.extend(advancedFormat)

import type {
  MappedRefunds,
  PolicyOutput,
  Refund,
  Refunds,
  RefundType,
} from 'types/externalData'

const getTextsByRefunds = (refunds: Refunds) => {
  const cash = (refund: Refund) => {
    const {
      gracePeriod: isGracePeriod,
      gracePeriodHours,
      refundPercent,
      refundDate,
      refundTime,
    } = refund

    const timeUnit = gracePeriodHours === 1 ? 'hour' : 'hours'

    if (isGracePeriod) {
      return `This booking is eligible for a <span>${refundPercent}%</span> refund within <span>${gracePeriodHours} ${timeUnit}</span> after booking.`
    }

    return `This booking is eligible for a <span>${refundPercent}%</span> refund until <span>${refundDate}</span> at <span>${refundTime}</span>.`
  }

  const credit = (refund: Refund, refundIndex: number) => {
    const {
      gracePeriod: isGracePeriod,
      gracePeriodHours,
      refundPercent,
      refundDate,
      refundTime,
    } = refund
    const isFirstRefund = refundIndex === 0
    const timeUnit = gracePeriodHours === 1 ? 'hour' : 'hours'

    if (isGracePeriod) {
      return `This booking is eligible for a <span>${refundPercent}%</span> for an Evolve Future Travel Credit <span>${gracePeriodHours} ${timeUnit}</span> after booking.`
    }

    if (isFirstRefund) {
      return `This booking is eligible for an Evolve Future Travel Credit for <span>${refundPercent}%</span> of what you’ve paid for if cancelled before <span>${refundDate}</span> at <span>${refundTime}</span>.`
    }

    return `After that, this booking is eligible for an Evolve Future Travel Credit for <span>${refundPercent}%</span> of what you’ve paid for if cancelled before <span>${refundDate}</span> at <span>${refundTime}</span>.`
  }

  const none = (_refund: Refund, refundIndex: number) => {
    const isFirstRefund = refundIndex === 0
    const isLastRefund = refundIndex === refunds.length - 1
    const previousRefund = refunds[refundIndex - 1]
    const previousRefundIsGracePeriod = previousRefund?.gracePeriod
    const previousRefundDate = previousRefund?.refundDate
    const previousRefundTime = previousRefund?.refundTime

    if (isFirstRefund) {
      return `Based on your trip dates, <span>this trip is not eligible for a refund if cancelled.</span>`
    }

    if (previousRefundIsGracePeriod && isLastRefund) {
      return 'After that, your trip is no longer eligible for a refund.'
    }

    return `On <span>${previousRefundDate}</span> at <span>${previousRefundTime}</span>, your trip will no longer be eligible for a refund.`
  }

  return {
    cash,
    credit,
    none,
  }
}

const getAlertByRefund = () => {
  const cash = (refund: Refund) => {
    const {
      gracePeriod: isGracePeriod,
      gracePeriodHours,
      refundPercent,
      refundDate,
    } = refund
    const formattedOrdinalDate = dayjs(refundDate).format('MMM Do')
    const timeUnit = gracePeriodHours === 1 ? 'hour' : 'hours'

    if (refundPercent === 100) {
      if (isGracePeriod) {
        return `Free cancellation for ${gracePeriodHours} ${timeUnit}.`
      } else {
        return `Free cancellation until ${formattedOrdinalDate}.`
      }
    } else {
      return 'This reservation is partially refundable.'
    }
  }
  const credit = () => {
    return 'Based on your trip dates, this reservation is partially refundable.'
  }
  const none = () => {
    return 'Based on your trip dates, this reservation is non-refundable.'
  }

  return { cash, credit, none }
}

const getTimelineByRefunds = () => {
  const cash = (refund: Refund) => {
    const { refundPercent } = refund
    return `${refundPercent}% refunded to original method of payment.`
  }

  const credit = (refund: Refund) => {
    const { refundPercent } = refund
    return `${refundPercent}% refund issued as Evolve Future Travel Credit.`
  }

  const none = () => {
    return 'No longer eligible for a refund.'
  }

  return {
    cash,
    credit,
    none,
  }
}

const composeAlert = (payload: Refunds): MappedRefunds => {
  const [firtsRefund] = payload
  const { refundType: id, refundDate: date } = firtsRefund
  const getText = getAlertByRefund()[id]
  const text = getText(firtsRefund)

  return [{ id, text, date }]
}

const composeTexts = (payload: Refunds): MappedRefunds => {
  return payload.map((refund, refundIndex) => {
    const { refundType: id, refundDate: date } = refund
    const getText = getTextsByRefunds(payload)[id]
    const text = getText(refund, refundIndex)

    return {
      text,
      id,
      date,
    }
  })
}

const composeTimeline = (payload: Refunds): MappedRefunds => {
  return payload.map((refund) => {
    const { refundType: id, refundDate: date } = refund
    const getText = getTimelineByRefunds()[id]
    const text = getText(refund)

    return {
      date,
      id,
      text,
    }
  })
}

const formatRefunds = (payload: Refunds) => {
  return payload.map((refund) => {
    const refundType = (refund.refundType?.toLowerCase() ||
      'none') as RefundType
    const refundDate = dayjs(refund.refundDate).format(
      DatesConstants.MONTH_NAME_LARGE_FORMAT,
    )
    const refundTime = dayjs(
      `${refund.refundDate} ${refund.refundTime}`,
    ).format(DatesConstants.HOUR_MINUTES)

    return {
      ...refund,
      refundType,
      refundDate,
      refundTime,
    }
  })
}

export type UseCancellationPolicy = (
  payload?: Refunds,
  mode?: PolicyOutput,
) => MappedRefunds

const useCancellationPolicy: UseCancellationPolicy = (
  payload,
  mode = 'text',
) => {
  if (!payload?.length) return []

  const formattedRefunds = formatRefunds(payload).filter(
    (formattedRefund, index) => {
      /* There is an issue in the API response
        returning cash refunds gracePeriod: true with 
        gracePeriodHours: 0, remove this filter
        if the issue is fixed.
      */
      const shouldSkipFirst =
        index === 0 &&
        formattedRefund.refundType === 'cash' &&
        formattedRefund.gracePeriod === true &&
        formattedRefund.gracePeriodHours === 0
      return !shouldSkipFirst
    },
  )

  const composerOptions = {
    alert: composeAlert,
    text: composeTexts,
    timeline: composeTimeline,
  }
  const mappedRefunds = composerOptions[mode]?.(formattedRefunds)

  return mappedRefunds
}

export {
  useCancellationPolicy,
  formatRefunds,
  composeTexts,
  composeAlert,
  composeTimeline,
  getTextsByRefunds,
  getAlertByRefund,
  getTimelineByRefunds,
}
