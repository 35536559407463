import { ReactElement } from 'react'
import classNames from 'classnames'

import styles from './PriceBreakdown.module.scss'
import type { ItemDetail } from './PriceBreakdown.types'
import ItemAmount from './ItemAmout'
import ItemToolTip from './ItemTooltip'

type OrderItemRowProps = {
  onClick?: VoidFunction
  item: ItemDetail
  isLabelBold?: boolean
  isValueBold?: boolean
}

const OrderItemRow = ({
  item,
  isLabelBold = true,
  isValueBold = false,
}: OrderItemRowProps) => {
  return (
    <div className={classNames(styles.order__row)}>
      <span className={classNames({ AB_TEST_GP385_DUE_BOLDS: isLabelBold })}>
        {item.label}
        <abbr>:</abbr>
        <ItemToolTip info={item.helpText} label={item.label} />
      </span>
      <ItemAmount amount={item.value} isValueBold={isValueBold} />
    </div>
  )
}

export default OrderItemRow
