import useSWR from 'swr'

type ErrorResponse = Error & {
  info: any
  status: number
}

export const fetcher = async (url) => {
  const res = await fetch(url, {
    headers: process.env.NEXT_PUBLIC_ORIGIN?.includes('evrinternal.com')
      ? {
          Authorization:
            'Basic ' +
            btoa(
              process.env.NEXT_PUBLIC_BASIC_AUTH_USER +
                ':' +
                process.env.NEXT_PUBLIC_BASIC_AUTH_PASSWORD,
            ),
        }
      : undefined,
  })

  if (!res.ok) {
    const error = new Error(
      'An error occurred while fetching the data.',
    ) as ErrorResponse
    // Attach extra info to the error object.
    error.info = await res.json()
    error.status = res.status
    throw error
  }

  return res.json()
}

const useGeolocation = () => {
  const { data, error, isValidating } = useSWR<
    { country: string },
    ErrorResponse
  >('/api/geoLocation', fetcher, {
    shouldRetryOnError: false,
  })

  return {
    country: data?.country,
    isLoading: !error && !data,
    error,
    isValidating,
  }
}

export default useGeolocation
