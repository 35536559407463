import { useRouter } from 'next/router'
import classNames from 'classnames'

import type { BookingDates } from 'context/BookingContext'

import { useCertainPage } from 'hooks/useCertainPage'

import { TextPolicy } from 'components/TextPolicy/TextPolicy'
import { TimelinePolicy } from 'components/TimelinePolicy/TimelinePolicy'

import styles from './CancellationPolicy.module.scss'
import { useCancellationPolicy } from './useCancellationPolicy'

import { DEFAULT_TEXT_MESSAGE } from 'constants/cancellationPolicy'

import type { Refunds } from 'types/externalData'

export type CancellationPolicyProps = {
  dates: BookingDates
  refunds?: Refunds
}

const CancellationPolicy = (props: CancellationPolicyProps) => {
  const { dates, refunds } = props
  const hasDates = !!(dates?.start || dates?.end)
  const cancellationIsModalMode = useCertainPage(
    /booking.*\/summary/,
    /booking.*\/payment-details/,
    /vacation-rentals\/[0-9].*\?[^]*inquiry_modal=open/,
  )
  const refundsForText = useCancellationPolicy(refunds, 'text')
  const refundsForTimeline = useCancellationPolicy(refunds, 'timeline')
  const hasRefunds =
    refunds &&
    refunds?.length > 0 &&
    refunds[0].refundType?.toLowerCase() !== 'none'
  const shouldRenderTextPolicy = hasDates
  const shouldRenderTimelinePolicy = hasDates

  if (!refunds) return null

  return (
    <section
      className={classNames(
        styles.section,
        {
          [styles.modalSection]: cancellationIsModalMode,
          [styles.modalSectionWithRefunds]: hasRefunds,
          [styles.noDates]: !hasDates,
        },
        'showScrollBar',
      )}
      id="cancellationPolicy"
    >
      <h3 className={styles.title}>Cancellation & Refund Policy</h3>
      <div className={styles.columnA}>
        {hasRefunds ? (
          <p className={styles.description}>
            We understand plans change! That’s why Evolve provides flexibility
            for cancellations and refunds.
          </p>
        ) : null}
        {shouldRenderTextPolicy ? (
          <>
            <TextPolicy refunds={refundsForText} />
          </>
        ) : (
          <p className={styles.description}>{DEFAULT_TEXT_MESSAGE}</p>
        )}
      </div>
      {shouldRenderTimelinePolicy ? (
        <div className={styles.columnB}>
          <TimelinePolicy refunds={refundsForTimeline} />
        </div>
      ) : null}
    </section>
  )
}

export { CancellationPolicy }
