import useSWR from 'swr'
import { useCallback } from 'react'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import { useDelayedRequestReport } from './useDelayedRequestReport'

import { quoteFetcher } from 'utils/clientFetching'
import { errorCodeMap } from 'utils/Listings'

import { QuotePayload } from 'types/externalData'

const QUOTE_REQUEST_REPORT_TIMEOUT = 3000

const getRequiredFieldsFromPayload = (
  payload: QuotePayload | null,
): Pick<
  QuotePayload['reservation'],
  'checkInDate' | 'checkOutDate' | 'numberOfAdults'
> => {
  const checkInDate = payload?.reservation?.checkInDate || ''
  const checkOutDate = payload?.reservation?.checkOutDate || ''
  const numberOfAdults = payload?.reservation?.numberOfAdults || 0

  return {
    checkInDate,
    checkOutDate,
    numberOfAdults,
  }
}
const isReadyToFetchQuote = (payload: QuotePayload | null): boolean => {
  const { checkInDate, checkOutDate, numberOfAdults } =
    getRequiredFieldsFromPayload(payload)

  return !!checkInDate && !!checkOutDate && numberOfAdults > 0
}

const useQuote = (payload: QuotePayload | null, _?: any) => {
  const shouldFetch = isReadyToFetchQuote(payload)
  const router = useAugmentedRouter()
  const { data, error, isValidating, mutate } = useSWR(
    !router.isFallback && shouldFetch !== false && payload
      ? ['', payload]
      : null,
    quoteFetcher,
    {
      shouldRetryOnError: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )

  const errorMessage =
    data?.errors || error
      ? error.info?.errors[0]?.errorMessage || errorCodeMap[1000]
      : ''

  const refreshQuote = useCallback(() => mutate(), [mutate])

  useDelayedRequestReport({
    isValidating,
    requestType: 'Quote Request',
    requestTimeout: QUOTE_REQUEST_REPORT_TIMEOUT,
    listingId: payload?.reservation.internalListingID || '',
    autoReport: true,
    error,
  })

  return {
    quote:
      payload?.reservation.checkInDate && payload?.reservation.checkOutDate
        ? data
        : null,
    isLoading: shouldFetch && !error && !data,
    isError: error,
    isValidating,
    errorMessage,
    refreshQuote,
  }
}

export default useQuote
