import React, { useEffect } from 'react'
import classNames from 'classnames'

import styles from './Modal.module.scss'

import CloseIcon from 'assets/icons/icon-close.svg'

export type ModalProps = {
  children?: React.ReactNode
  className?: string
  isOpen: boolean
  onCloseClick: () => void
} & React.HTMLProps<HTMLDivElement>

function Modal(props: ModalProps) {
  const { children, className = '', isOpen, onCloseClick, ...rest } = props

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('noScroll')
    } else {
      document.body.classList.remove('noScroll')
    }
  }, [isOpen])

  const onClickOverlay = (evt: React.MouseEvent<HTMLDivElement>) => {
    // Only close modal if click was in the overlay
    if ((evt.target as HTMLElement).id !== 'overlay') return
    onCloseClick()
  }

  return (
    <div
      className={classNames(styles.overlay, className, {
        [styles.open]: isOpen,
      })}
      data-testid="overlay"
      id="overlay"
      onClick={onClickOverlay}
    >
      <div
        aria-hidden={!isOpen}
        className={styles.content}
        role="dialog"
        {...rest}
      >
        <button
          aria-label="Close modal"
          className={styles.button}
          onClick={onCloseClick}
          type="button"
        >
          <CloseIcon className={styles.closeIcon} height={16} width={16} />
        </button>
        {children}
      </div>
    </div>
  )
}

export default Modal
