import dayjs from 'dayjs'

import type { Availability } from '../types/externalData'

import DatesConstants from 'constants/dates'

const getSelectedAvgPrice = (
  availability: Availability,
  startDate: string,
  endDate: string,
) => {
  const start = dayjs(startDate).format(DatesConstants.DEFAULT)
  const end = dayjs(endDate).format(DatesConstants.DEFAULT)
  const sliceIndices: number[] = []
  // Slice availability based on indices of start/end dates
  for (let i = 0; i < availability.length; i++) {
    const current = dayjs(availability[i].date).format(DatesConstants.DEFAULT)
    if (current === start || current === end) {
      sliceIndices.push(i)
    }
  }
  const selectedDates = availability.slice(sliceIndices[0], sliceIndices[1])
  // Calculate average of selected dates
  return (
    selectedDates.reduce((acc: any, curr: any) => {
      return acc + curr.price
    }, 0) / selectedDates.length
  )
}

export default getSelectedAvgPrice
